import React from 'react';

import TalkAbout from '../../components/TalkAbout/TalkAbout';

import ctsSystemMobile from '../../assets/about/mobile/cts-system-1.png';
import ctsSystemTablet from '../../assets/about/tablet/cts-system-1.png';
import ctsSystemDesktop from '../../assets/about/desktop/cts-system-1.png';
import BackgroundImage from '../../assets/shared/desktop/bg-pattern-leaf.svg';

const System = () => {
  return ( 
    <main className="background background-about">
      <section className="about new-container">
        <div className="about-header">
          <picture className="about-header-img">
            <source srcSet={ctsSystemDesktop} media="(min-width: 1024px)" />
            <source srcSet={ctsSystemTablet}  media="(min-width: 495px)" />
            <img src={ctsSystemMobile} alt="Team work" />
          </picture>
          <div className="about-header-text">
            <h1>System</h1>
            <p className="small-text">
              The solution starts with the Case Management System providing the necessary daily cases that are scheduled for each courtroom. A SQL query or an API extracts the case data and a Python script formats and sends the data to the message queuing service which the Raspberry Pi then receives by way of an Ubuntu Core Snap. The message queuing service can reside locally on the customers servers or on Amazon Web Services using AWS Core IoT to manage the MQTT publish-subscribe messaging.<br/>
            </p>
            <p className="small-text">
	  			<em>Same Ubuntu Snap on SD Card can be used with either:</em><br/>
	  			RPi 4 Model B - RPi 3 Model B - RPi Zero 2 W<br/>  
            </p>
          </div>
        </div>
      </section>
      <div className="background-image background-image-top">
          <img src={BackgroundImage} alt="Deco" />
      </div>

      <div className="background-image background-image-about">
        <img src={BackgroundImage} alt="Deco" />
      </div>

      <TalkAbout />
    </main>

  );
}
 
export default System;

import React from 'react';

import TalkAbout from '../../components/TalkAbout/TalkAbout';
import HOJMobile from '../../assets/about/mobile/hoj-2.png';
import HOJTablet from '../../assets/about/tablet/hoj-2.png';
import HOJDesktop from '../../assets/about/desktop/hoj-2.png';
import BackgroundImage from '../../assets/shared/desktop/bg-pattern-leaf.svg';

const About = () => {
  return ( 
    <main className="background background-about">
      <section className="about new-container">
        <div className="about-header">
          <picture className="about-header-img">
            <source srcSet={HOJDesktop} media="(min-width: 1024px)" />
            <source srcSet={HOJTablet}  media="(min-width: 495px)" />
            <img src={HOJMobile} alt="Team work" />
          </picture>
          <div className="about-header-text">
            <h1>About Us</h1>
            <p className="small-text">
                With origins directly from the California Superior Court, largest in the United States, we know what works and what doesn't.
            </p>
            <p className="small-text">
                Our technical expertise in hardware, software and graphic design, all come together in how we present our signage solution.
            </p>            
            <p className="small-text">
                Our company goals are to deliver a secure, reliable, cost effective and energy efficient product. We trust that these are your goals as well.
            </p>            
          </div>
        </div>
      </section>
      <div className="background-image background-image-top">
          <img src={BackgroundImage} alt="Deco" />
      </div>

      <div className="background-image background-image-about">
        <img src={BackgroundImage} alt="Deco" />
      </div>

      <TalkAbout />
    </main>

  );
}
 
export default About;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Header from './components/Header/Header';
import Contact from './pages/Contact/Contact';
import System from './pages/System/System';
import Pricing from './pages/Pricing/Pricing';
import Footer from './components/Footer/Footer';
import './styles/index.scss';


const App = () => {
  return ( 
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/system" element={<System />} />
        <Route exact path="/pricing" element={<Pricing />} />        
      </Routes>
      <Footer />
    </Router>
  );
}
 
export default App;

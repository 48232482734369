import React from 'react';
import { Link } from 'react-router-dom';

import HeaderLogo from '../../assets/shared/desktop/court_signage-black.png';
import Menu from './Menu/Menu';

const Header = () => {
  return (
    <header className="container">
      <div className="header">
        <Link to="/">
          <img className="logo" src={HeaderLogo} alt="Court Signage" />
        </Link>

        <nav className="header-nav">
          <ul className="header-nav-list">
            <li className="li_nk"><Link to="/about">ABOUT</Link></li>
            <li className="li_nk"><Link to="/system">SYSTEM</Link></li>
            <li className="li_nk"><Link to="/pricing">PRICING</Link></li>
            <li className="li_nk"><Link to="/contact">CONTACT</Link></li>
          </ul>
        </nav>

        <Menu />
      </div>
    </header> 
  );
}
 
export default Header;

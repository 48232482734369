import React from 'react';

import ButtonLocation from '../../components/ButtonLocation/ButtonLocation';
import BackgroundImage from '../../assets/shared/desktop/bg-pattern-leaf.svg';

import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';


const Contact = () => {

	const SERVICE_ID = 'service_mhlw9zp';
	const TEMPLATE_ID = 'template_xflxuux';
	const USER_ID = 'bd58eigeUHu2-rMT-';
	

	  const handleOnSubmit = (e) => {
	    e.preventDefault();
	    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
	      .then((result) => {
	        console.log(result.text);
	        Swal.fire({
	          icon: 'success',
				title: 'Message Sent Successfully'
	        })
	      }, (error) => {
	        console.log(error.text);
	        Swal.fire({
	          icon: 'error',
	          title: 'Ooops, something went wrong',
	          text: error.text,
	        })
	      });
	    e.target.reset()
	  };
  
  return ( 
    <main className="main-contact">
      <section className="contact new-container">
        <div className="contact-text">
          <h1>Contact Us</h1>
          <p className="small-text">
            Ready to take your court signage to the next level? Let’s talk about your project to find out how we can help you to implement department signage at your courthouse. If you are looking for a solution that is secure, reliable, cost effective and energy efficient, then drop us a line.
          </p>
        </div>
        
        
	    <Form onSubmit={handleOnSubmit}>
          <div className="form-input">
	          <Form.Field
	            id='form-input-control-email'
	            control={Input}
	            label='Email'
	            name='user_email'
	            placeholder='Email…'
	            required
	            icon='mail'
	            iconPosition='left'
	          />
          </div>	      
          <div className="form-input">
	          <Form.Field
	            id='form-input-control-last-name'
	            control={Input}
	            label='Name'
	            name='user_name'
	            placeholder='Name…'
	            required
	            icon='user circle'
	            iconPosition='left'
	          />
          </div>
          <div className="form-area">
	          <Form.Field
	            id='form-textarea-control-opinion'
	            control={TextArea}
	            label='Message'
	            name='user_message'
	            placeholder='Message…'
	            required
	          />
          </div>	      
	      <Button type='submit' color='green'>Send</Button>
	    </Form>
        
        
        
      </section>

      <ButtonLocation />
      <div className="background-image background-image-bottom">
          <img src={BackgroundImage} alt="Deco" />
        </div>
    </main>
  );
}
 
export default Contact;

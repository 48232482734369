import React from 'react';

import TalkAbout from '../../components/TalkAbout/TalkAbout';
import ctsPricingWhiteMobile from '../../assets/about/mobile/cts-pricing-white-1.png';
import ctsPricingWhiteTablet from '../../assets/about/tablet/cts-pricing-white-1.png';
import ctsPricingWhiteDesktop from '../../assets/about/desktop/cts-pricing-white-1.png';
import BackgroundImage from '../../assets/shared/desktop/bg-pattern-leaf.svg';

const Pricing = () => {
  return ( 
    <main className="background background-about">
      <section className="about new-container">
        <div className="about-header">
          <picture className="about-header-img">
            <source srcSet={ctsPricingWhiteDesktop} media="(min-width: 1024px)" />
            <source srcSet={ctsPricingWhiteTablet}  media="(min-width: 495px)" />
            <img src={ctsPricingWhiteMobile} alt="Team work" />
          </picture>
          <div className="about-header-text">
            <h1>Pricing</h1>
            <p className="small-text">
              The option of purchasing Raspberry Pi's and memory outside of the agreement is possible if the customer already has a preferred vendor. Configuration can be done either on site or virtually. Necessary configuration documents will be provided to assist the customer in setting up the message queuing service.
            </p>
          </div>
        </div>
      </section>
      <div className="background-image background-image-top">
          <img src={BackgroundImage} alt="Deco" />
      </div>

      <div className="background-image background-image-about">
        <img src={BackgroundImage} alt="Deco" />
      </div>

      <TalkAbout />
    </main>

  );
}
 
export default Pricing;

import React from 'react';

import CanadaPicture from '../../assets/shared/desktop/illustration-canada.svg';
import AustraliaPicture from '../../assets/shared/desktop/illustration-australia.svg';
import UnitedKingdomPicture from '../../assets/shared/desktop/illustration-united-kingdom.svg';

const ButtonLocation = () => {
  return ( 
    <section className="button-location container">
      <div className="button-location-container">
        <div className="button-location-content">
          <div className="work-content-img">
            <div className="work-img-friendly">
              <img src={CanadaPicture} alt="Canada Location" />
            </div>
          </div>

        </div>
        <div className="button-location-content">
          <div className="work-content-img">
            <div className="work-img-passionate">
              <img src={AustraliaPicture} alt="Australia Location" />
            </div>
          </div>

        </div>
        <div className="button-location-content">
          <div className="work-content-img">
            <div className="work-img-resourceful">
              <img src={UnitedKingdomPicture} alt="United Kingdom location" />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
 
export default ButtonLocation;
